import React from 'react';
import { Divider, message, Space } from 'antd';
import { useCart } from './cart-hooks';
import { Link } from 'react-router-dom';
import "./productGrid.css";
//import img from "./imgs/2DF_804.jpg";

export function ProductGrid({catalogue}) {
    
    const {addToCart} = useCart();
    const API_URL = process.env.REACT_APP_NODE_SERVER_BASEURL;
    //console.log("catalogue: "+JSON.stringify(catalogue));
    //console.log('productGrid catalog: '+ JSON.stringify(catalogue));
    return (
        <div className='flex-container'>
            <Divider>{catalogue[0].category}</Divider>
            <div className='itemsbox'>
            {
               catalogue.map(product => (
                <div key={product.id} className='flex-item'>
               <div>
                <Link key={product.id} to={"/product/"+product.id} state={product}>
                   <img alt={product.name} className="product-img" src={API_URL+'/uploads/'+product.imgs.regs[0]} />
                </Link>
                </div>
                <p className='desc'>
                {product.description}
                </p>
                <h4>
                {product.brand}
                </h4>
                <div>
                <span><h4>$ {product.price}</h4></span>
                <span>
                    <Space>
                       <button className='productbtn' onClick={() => {
                                               addToCart(product);
                                               message.success({content:'Successfully Added to Your Cart!', style:{marginTop:'16vh',},}, 3);}}>Add to Cart</button>
                    </Space>
                </span>
                </div>
            </div>
               ))
            }
            </div>
        </div>
    )
}  